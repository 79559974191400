// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyD6toV1NdUTojdiwriqUlgj60r8rmN_zio',
  authDomain: 'nyushiseikai.firebaseapp.com',
  databaseURL: 'https://nyushiseikai.firebaseio.com',
  projectId: 'nyushiseikai',
  storageBucket: 'nyushiseikai.appspot.com',
  messagingSenderId: '181739125130',
  appId: '1:181739125130:web:1761fc6180dfd51468b073'
};

export const firebaseLocation = 'asia-northeast1';

export const commonIdConfig = {
  authorizationEndpoint: 'https://id.obunsha.co.jp/v1/authorize',
  redirectUri: 'https://kakomon.obunsha.co.jp/c/sign-in/callback',
  clientId: 'TYXyfUH9yaic7tuYTSH6KsHMe6wtYlsxQHX4be92d54s',
  profileUrl: 'https://id.obunsha.co.jp/settings/profile/',
  faqUrl: 'https://id.obunsha.co.jp/help/',
  ssc: {
    cookieApiUri: 'https://api.ssc.obunsha.co.jp/v1/cookies'
  }
};

export const passNaviConfig = {
  faqUrl: 'https://passnavi.obunsha.co.jp/faq/'
};

export const gaConfig = ['G-MK3L2L2PG2', 'G-M5T8L37HM4', 'UA-187423094-1'];

export const redirectDomainConfig = {
  from: 'nyushiseikai.web.app',
  to: 'kakomon.obunsha.co.jp'
};

export const manualPdfFiles = {
  userManual: 'https://storage.googleapis.com/nyushiseikai.appspot.com/help-manual/nyushidigi_standard_manual.pdf',
  adminManual: 'https://storage.googleapis.com/nyushiseikai.appspot.com/help-manual/nyushidigi_dantaikanri_manual.pdf'
};
export const downloadFiles = {
  universitiesListPdf: 'https://storage.googleapis.com/nyushiseikai.appspot.com/help-manual/nyushidigi_daigakulist_obunsha.pdf',
  membersImportTemplateCsv: 'https://storage.googleapis.com/nyushiseikai.appspot.com/help-manual/template.csv',
  membersImportManualPdf: 'https://storage.googleapis.com/nyushiseikai.appspot.com/help-manual/nyushidigi_dantaikanri_manual.pdf'
};

export const microCmsConfig = {
  adminUrl: 'https://kakomon.microcms.io/apis/news'
};

export const stripeConfig = {
  userPaymentsUrl: 'https://dashboard.stripe.com/search?query=<user-id>%20is%3Apayment'
};

export const cloudfrontHost = 'https://d3jo2rg08g5mt0.cloudfront.net/';
export const lambdaHost = 'https://qnlu5ls7vrj4y3t3f5bthdvuu40bzkor.lambda-url.ap-northeast-1.on.aws/';

export const sentryDsn = 'https://2b6ccc4110824c9faab09ec89f0557b7@o1071032.ingest.sentry.io/6067692';
export const release = '20241127-release';
